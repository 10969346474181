import Vue from 'vue';

Vue.directive('lazyload', {
    inserted: (el) => {
        const getImgElements = (el, incrementIndex) => {
            const id = el.dataset?.id;
            const index = el.dataset?.index;
            return document.querySelectorAll(`img[data-id="${id}"][data-index="${parseInt(index) + (incrementIndex ?? 0)}"]`);
        }

        const loadImage = (imgEl) => {
            const src = imgEl.dataset?.lazy;
            if (!src) return;

            imgEl.src = src;
            const onLoad = () => {
                imgEl.removeAttribute('data-lazy');
                imgEl.removeEventListener('load', onLoad);
            };

            imgEl.addEventListener('load', onLoad);
        };
        const loadAndUnobserveImages = (imageElements, observer) => {
            imageElements?.forEach((imgElement) => {
                try {
                    loadImage(imgElement);
                } catch (error) {
                    console.error('Error loading image:', error);
                } finally {
                    observer.unobserve(imgElement);
                }
            });
        };
        const loadNextImageIfSwipe = (el, observer) => {
            const swiperClass = el.getAttribute("class");
            if (!swiperClass || !swiperClass.includes("swiper-slide-image")) {
                return;
            }

            loadAndUnobserveImages(getImgElements(el), observer);
            loadAndUnobserveImages(getImgElements(el, 1), observer);
        };

        const callIntersectionApi = () => {
            const lazyLoadingIO = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const el = entry.target;
                        loadImage(el);
                        observer.unobserve(el);
                        loadNextImageIfSwipe(el, observer);
                    }
                });
            }, {root: null, threshold: 0.1});

            lazyLoadingIO.observe(el);
        };

        window.IntersectionObserver ? callIntersectionApi() : loadImage(el);
    },
});

