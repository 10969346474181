<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  metaInfo: () => ({
    title: '오렌즈 - 아름답고 건강한 아이 스타일링',
    meta: [
      { vmid: 'viewport', name: 'viewport', content: 'width=750, maximum-scale=1.0, user-scalable=no' },
      { vmid: 'og:title', property: 'og:title', content: '오렌즈 - 아름답고 건강한 아이 스타일링' },
      { vmid: 'og:image', property: 'og:image', content: `${process.env.VUE_APP_BASE_URL}/images/share_image.png` },
    ],
  }),
};
</script>