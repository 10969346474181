// packages
import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

// store modules
import app from './modules/app';
import sns from './modules/sns';
import code from './modules/code';
import share from './modules/share';
import product from  './modules/product';
import shop from  './modules/shop';
import coupon from  './modules/coupon';

// store definition
const store = {
  // state, members, modules, etc
  modules: {
    app,
    sns,
    code,
    share,
    product,
    shop,
    coupon
  },
};

// store
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [pathify.plugin], // activate plugin
  ...store,
});