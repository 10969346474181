import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import mdiSvgIcons from '@/plugins/vuetify/mdiSvgIcons';
import breakpoint from '@/plugins/vuetify/breakpoint';
import ko from 'vuetify/es5/locale/ko'; // Translation provided by Vuetify (javascript)

// 전체 페이지 ripple 제거
Vue.mixin({
  directives: {
    ripple: {
      inserted: () => {
        // ripple 제거
      },
    },
  },
});

Vue.use(Vuetify);

export default new Vuetify({
  icons: mdiSvgIcons,
  breakpoint,
  theme: {
    themes: {
      light: {
        primary: '#000000',
        gray: '#777',
        olensPink: '#FF67A9'
      },
    },
  },
  lang: {
    locales: {ko},
    current: 'ko',
  },
});
