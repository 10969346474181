import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import goTo from 'vuetify/lib/services/goto';
import { isMobile, isIOS } from 'mobile-device-detect';
import axios from 'axios';
import store from '@/store';

// layouts
const BlankLayout = () => import(/* webpackChunkName: "chunk/mainLayout" */ '@/components/layouts/BlankLayout');
const MainLayout = () => import(/* webpackChunkName: "chunk/mainLayout" */ '@/components/layouts/MainLayout');
const SubLayout = () => import(/* webpackChunkName: "chunk/subLayout" */ '@/components/layouts/SubLayout');
const SearchLayout = () => import(/* webpackChunkName: "chunk/subLayout" */ '@/components/layouts/SearchLayout');

const ProductLayout = () => import(/* webpackChunkName: "chunk/ProductLayout" */ '@/components/layouts/ProductLayout.vue');
// common
const NotFound = () => import(/* webpackChunkName: "chunk/notFound" */ '@/components/common/error/NotFound');

// views
const MyDevice = () => import(/* webpackChunkName: "chunk/test" */ '@/components/views/test/MyDevice');
const IntroIndex = () => import(/* webpackChunkName: "chunk/intro" */ '@/components/views/intro/IntroIndex');
const MainView = () => import(/* webpackChunkName: "chunk/main" */ '@/components/views/MainView');
const ProductSearch = () => import(/* webpackChunkName: "chunk/search" */ '@/components/views/ProductSearch');
const ProductBundle = () => import(/* webpackChunkName: "chunk/search" */ '@/components/views/ProductBundle');
const MyPage = () => import(/* webpackChunkName: "chunk/member" */ '@/components/views/member/MyPage');
const MemberPassword = () => import(/* webpackChunkName: "chunk/member" */ '@/components/views/member/MemberPassword');
const MyReview = () => import(/* webpackChunkName: "chunk/member" */ '@/components/views/member/MyReview');
const MyQna = () => import(/* webpackChunkName: "chunk/member" */ '@/components/views/member/MyQna');
const MyPoint = () => import(/* webpackChunkName: "chunk/member" */ '@/components/views/member/MyPoint');
const OlensLogin = () => import(/* webpackChunkName: "chunk/login" */ '@/components/views/member/OlensLogin');
const OlensLoginNaver = () => import(/* webpackChunkName: "chunk/login" */ '@/components/views/member/OlensLoginNaver');
const OlensLoginKakao = () => import(/* webpackChunkName: "chunk/login" */ '@/components/views/member/OlensLoginKakao');
const InquireId = () => import(/* webpackChunkName: "chunk/inquire" */ '@/components/views/member/InquireId');
const InquirePassword = () => import(/* webpackChunkName: "chunk/inquire" */ '@/components/views/member/InquirePassword');
const MemberJoin = () => import(/* webpackChunkName: "chunk/join" */ '@/components/views/member/MemberJoin');
const MemberJoinSocial = () => import(/* webpackChunkName: "chunk/join" */ '@/components/views/member/MemberJoinSocial');
const MemberOut = () => import(/* webpackChunkName: "chunk/join" */ '@/components/views/member/MemberOut');
const MemberSetting = () => import(/* webpackChunkName: "chunk/join" */ '@/components/views/member/MemberSetting');
const MemberAuthentication = () => import(/* webpackChunkName: "chunk/join" */ '@/components/views/member/MemberAuthentication');
const MemberJoinStepTwo = () => import(/* webpackChunkName: "chunk/my-page" */ '@/components/views/member/MemberJoinStepTwo');
const MemberJoinStepThree = () => import(/* webpackChunkName: "chunk/my-page" */ '@/components/views/member/MemberJoinStepThree');
const MemberInfo = () => import(/* webpackChunkName: "chunk/my-page" */ '@/components/views/member/MemberInfo');
const ProductView = () => import(/* webpackChunkName: "chunk/product" */ '@/components/views/ProductView');
const ReviewList = () => import(/* webpackChunkName: "chunk/reviews" */ '@/components/views/ReviewList');
const ReviewWrite = () => import(/* webpackChunkName: "chunk/reviews" */ '@/components/views/ReviewWrite');
const MemberQna = () => import(/* webpackChunkName: "chunk/reviews" */ '@/components/views/MemberQna');
const RoundUp = () => import(/* webpackChunkName: "chunk/roundup" */ '@/components/views/RoundUp');
const RoundUpResult = () => import(/* webpackChunkName: "chunk/roundup" */ '@/components/views/RoundUpResult');
const FavoriteList = () => import(/* webpackChunkName: "chunk/favorites" */ '@/components/views/FavoriteList');
const FranchiseViewTab = () => import(/* webpackChunkName: "chunk/franchise" */ '@/components/views/FranchiseViewTab');
const FranchiseList = () => import(/* webpackChunkName: "chunk/franchise" */ '@/components/views/FranchiseList');
const FranchiseFavorite = () => import(/* webpackChunkName: "chunk/franchise" */ '@/components/views/FranchiseFavorite');
const EventList = () => import(/* webpackChunkName: "chunk/event" */ '@/components/views/EventList');
const EventView = () => import(/* webpackChunkName: "chunk/event" */ '@/components/views/EventView');
const NoticeList = () => import(/* webpackChunkName: "chunk/notice" */ '@/components/views/NoticeList');
const NoticeView = () => import(/* webpackChunkName: "chunk/notice" */ '@/components/views/NoticeView');
const FaqList = () => import(/* webpackChunkName: "chunk/faq" */ '@/components/views/FaqList');
const BrandTab = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandTab');
const BrandOlensTab = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandOlensTab');
const BrandOlensIntroduce = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandOlensIntroduce');
const BrandOlensPressList = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandOlensPressList');
const BrandOlensPressView = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandOlensPressView');
const BrandOlensSponsorList = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandOlensSponsorList');
const BrandOlensSponsorView = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandOlensSponsorView');
const BrandMuseTab = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandMuseTab');
const BrandMuseView = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandMuseView');
// const BrandMuseGallery = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandMuseGallery');
const BrandMuseViewTemp = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandMuseViewTemp');
const BrandAcademy = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandAcademy');
const BrandVision = () => import(/* webpackChunkName: "chunk/brand" */ '@/components/views/Brand/BrandVision');
const ClinicTab = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicTab');
const ClinicCampaignTab = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicCampaignTab');
const ClinicCampaignPastList = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicCampaignPastList');
const ClinicCampaignView = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicCampaignView');
const ClinicCampaignPastView = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicCampaignPastView');
const ClinicManualTab = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicManualTab');
const ClinicManualList = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicManualList');
const ClinicManualView = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicManualView');
const ClinicManualAstigmaticTest = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicManualAstigmaticTest');
const ClinicVisionCenterTab = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicVisionCenterTab');
const ClinicVisionCenter = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicVisionCenter');
const ClinicVisionCenterTestProcess = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicVisionCenterTestProcess');
const ClinicVisionCenterBenefit = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicVisionCenterBenefit');
const ClinicVisionCenterMap = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicVisionCenterMap');
const ClinicReservation = () => import(/* webpackChunkName: "chunk/clinic" */ '@/components/views/Clinic/ClinicReservation');
const FranchiseInfoTab = () => import(/* webpackChunkName: "chunk/franchise" */ '@/components/views/FranchiseInfo/FranchiseInfoTab');
const FranchiseInfo = () => import(/* webpackChunkName: "chunk/franchise" */ '@/components/views/FranchiseInfo/FranchiseInfo');
const FranchiseInfoInterior = () => import(/* webpackChunkName: "chunk/franchise" */ '@/components/views/FranchiseInfo/FranchiseInfoInterior');
const FranchiseInfoCost = () => import(/* webpackChunkName: "chunk/franchise" */ '@/components/views/FranchiseInfo/FranchiseInfoCost');
const NiceBlank = () => import(/* webpackChunkName: "chunk/nice" */ '@/components/views/member/NiceBlank');
const NiceStart = () => import(/* webpackChunkName: "chunk/nice" */ '@/components/views/member/NiceStart');
const NiceSuccess = () => import(/* webpackChunkName: "chunk/nice" */ '@/components/views/member/NiceSuccess');
const NiceFail = () => import(/* webpackChunkName: "chunk/nice" */ '@/components/views/member/NiceFail');
const MemberPolicy = () => import(/* webpackChunkName: "chunk/member" */ '@/components/views/member/MemberPolicy');
const MemberService = () => import(/* webpackChunkName: "chunk/member" */ '@/components/views/member/MemberService');
const MemberServiceLocation = () => import(/* webpackChunkName: "chunk/member" */ '@/components/views/member/MemberServiceLocation');
const SampleRequest = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/sample/SampleRequest');
const SampleRequestList = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/sample/SampleList');
const SampleHistoryViewTab = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/sample/SampleHistoryViewTab');
const SampleHistoryCurrent = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/sample/SampleHistoryCurrent');
const SampleHistoryPast = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/sample/SampleHistoryPast');
const SampleRequestDetail = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/sample/SampleRequestDetail');

const VisitHistoryViewTab = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/visit/VisitHistoryViewTab');
const VisitHistoryCurrent = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/visit/VisitHistoryCurrent');
const VisitHistoryPast = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/visit/VisitHistoryPast');
const VisitRequestDetail = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/visit/VisitRequestDetail');
const CouponViewTab = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/coupon/CouponViewTab');
const OwnedCoupon = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/coupon/OwnedCoupon');
const UsedCoupon = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/coupon/UsedCoupon');
const PurchaseList = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/PurchaseListView');
const PurchaseDetail = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/PurchaseDetailView');
const CartView = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/CartView');
const PopUpStore = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/PopUpStore');
const CheckVerifyCoupon = () => import(/* webpackChunkName: "chunk/sample" */ '@/components/views/CheckVerifyCoupon');
const routes = [
  layout('/intro', BlankLayout, [
    route('index', IntroIndex, { name: 'intro.index', meta: { title: '오렌즈 인트로' } }),
  ]),
  layout('/nice', BlankLayout, [
    route('blank', NiceBlank, { name: 'nice.blank', meta: { title: 'NICE 인증 시작전 빈 페이지' } }),
    route('start', NiceStart, { name: 'nice.start', meta: { title: 'NICE 인증 시작' } }),
    route('success', NiceSuccess, { name: 'nice.success', meta: { title: 'NICE 인증 성공' } }),
    route('fail', NiceFail, { name: 'nice.fail', meta: { title: 'NICE 인증 실패' } }),
  ]),
  layout('/', MainLayout, [
    route('main/index', null, {
          redirect: () => { // 기존 url redirect 처리
            return { name: 'main', params: null, query: null };
          },
        },
    ),
    route('main/temp', null, {
          redirect: () => { // 기존 url redirect 처리
            return { name: 'main', params: null, query: null };
          },
        },
    ),
    route('product/list-by-main/1', null, {
          redirect: () => { // 기존 url redirect 처리
            return { name: 'roundup.result', params: null, query: { uc: 'UC001' } };
          },
        },
    ),
    route('community/event', null, {
          redirect: () => { // 기존 url redirect 처리
            return { name: 'event', params: null, query: null };
          },
        },
    ),
    route('main/AllMenu', null, {
          redirect: () => { // 기존 url redirect 처리
            return { name: 'main', params: null, query: null };
          },
        },
    ),
    route('main/:tab?', MainView, { name: 'main', meta: { title: '오렌즈 메인' }, alias: [''] }),
  ]),
  layout('/', SearchLayout, [
    route('search', ProductSearch, { name: 'search', meta: { title: '검색' } }),
  ]),
  layout('/', ProductLayout, [
    route('product/:productId', ProductView, { name: 'product', meta: { title: '오렌즈 상품' } }),
  ]),
  layout('/', SubLayout, [
    route('policy', MemberPolicy, { name: 'member.policy', meta: { title: '개인정보 처리방침', noFooter: false } }),
    route('service', MemberService, { name: 'member.service', meta: { title: '이용약관', noFooter: false } }),
    route('service-location', MemberServiceLocation, { name: 'member.service-location', meta: { title: '위치정보 이용약관', noFooter: false } }),
    route('member/out', MemberOut, { name: 'member.out', meta: { title: '회원 탈퇴' } }),
    route('member/setting', MemberSetting, { name: 'member.setting', meta: { title: '수신 설정' } }),
    route('member/info', MemberInfo, { name: 'member.info', meta: { title: '내정보 설정' } }),
    route('member/authentication', MemberAuthentication, { name: 'member.authentication', meta: { title: '본인 인증' } }),
    route('password/edit', MemberPassword, { name: 'password.edit', meta: { title: '비밀번호 변경', noFooter: true } }),
    route('join', MemberJoin, { name: 'join', meta: { title: '회원 가입', noFooter: true } }),
    route('join/social', MemberJoinSocial, { name: 'join.social', meta: { title: '회원 가입', noFooter: true } }),
    route('join/step-two', MemberJoinStepTwo, { name: 'join.step.two', meta: { title: '회원 가입 2단계' } }),
    route('join/step-three', MemberJoinStepThree, { name: 'join.step.three', meta: { title: '회원 가입 3단계' } }),
    route('login', OlensLogin, { name: 'login', meta: { title: '로그인' } }),
    route('login/shop/:shopId', OlensLogin, { name: 'login.shop', meta: { title: '로그인(가맹점)' } }),
    route('login/naver', OlensLoginNaver, { name: 'login.naver', meta: { title: '네이버 로그인 완료' } }),
    route('login/kakao', OlensLoginKakao, { name: 'login.kakao', meta: { title: '카카오 로그인 완료' } }),
    route('inquire/id', InquireId, { name: 'inquire.id', meta: { title: '아이디 찾기' } }),
    route('inquire/password', InquirePassword, { name: 'inquire.password', meta: { title: '비밀번호 찾기' } }),
    route('my-page', MyPage, { name: 'my-page', meta: { title: '마이페이지', requireToken: true } }),
    route('my-review', MyReview, { name: 'my-review', meta: { title: '내가 작성한 리뷰', requireToken: true } }),
    route('my-qna', MyQna, { name: 'my-qna', meta: { title: '내가 작성한 문의', requireToken: true } }),
    route('my-point', MyPoint, { name: 'my-point', meta: { title: '포인트 상세정보', requireToken: true } }),
    route('reviews', ReviewList, { name: 'reviews', meta: { title: '리뷰' } }),
    route('review/write/:productId?', ReviewWrite, { name: 'review.write', meta: { title: '리뷰 작성', requireToken: true } }),
    route('qna', MemberQna, { name: 'qna', meta: { title: '문의하기', requireToken: true } }),
    route('roundup', RoundUp, { name: 'roundup', meta: { title: '모아보기' } }),
    route('roundup/result', RoundUpResult, { name: 'roundup.result', meta: { title: '모아보기 결과' } }),
    route('product/bundle/:bundleId', ProductBundle, { name: 'product.bundle', meta: { title: '모아보기 결과' } }),
    route('favorites', FavoriteList, { name: 'favorites', meta: { title: '관심상품' } }),
    route('franchise', FranchiseViewTab, {
      children: [
        route('list', FranchiseList, { name: 'franchise.list', meta: { title: '매장' }, alias: [''] }),
        route('favorite', FranchiseFavorite, { name: 'franchise.favorite', meta: { title: '관심매장' } }),
      ],
    }),
    route('event/list/:tab?', EventList, { name: 'event', meta: { title: '이벤트' } }),
    route('event/view/:brdPostId', EventView, { name: 'event.view', meta: { title: '이벤트 상세' } }),
    route('notice', NoticeList, { name: 'notice.list', meta: { title: '공지사항' } }),
    route('notice/:noticeSeq', NoticeView, { name: 'notice.view', meta: { title: '공지사항 상세' } }),
    route('faq/:tab?', FaqList, { name: 'faq', meta: { title: 'FAQ' } }),
    route('brand', BrandTab, {
      children: [
        route('olens', BrandOlensTab, {
          children: [
            route('../olens', BrandOlensIntroduce, { name: 'brand.olens', meta: { title: '브랜드' }, alias: [''] }),
            route('press', BrandOlensPressList, { name: 'brand.olens.press', meta: { title: '언론 속 오렌즈' } }),
            route('press/:pressSeq', BrandOlensPressView, { name: 'brand.olens.press.view', meta: { title: '언론 속 오렌즈' } }),
            route('sponsor', BrandOlensSponsorList, { name: 'brand.olens.sponsor', meta: { title: '스타협찬' } }),
            route('sponsor/:sponsorSeq', BrandOlensSponsorView, { name: 'brand.olens.sponsor.view', meta: { title: '스타협찬' } }),
          ],
        }),
        route('muse', BrandMuseTab, {
          children: [
            route('../muse', BrandMuseView, { name: 'brand.muse', meta: { title: 'OLENS MUSE' }, alias: [''] }),
            route('gallery', BrandMuseViewTemp, { name: 'brand.muse.gallery', meta: { title: 'OLENS MUSE GALLERY' } }),
          ],
        }),
        route('academy', BrandAcademy, { name: 'brand.academy', meta: { title: '아카데미' } }),
        route('vision', BrandVision, { name: 'brand.vision', meta: { title: '사회공헌' } }),
      ],
    }),
    route('clinic', ClinicTab, {
      children: [
        route('campaign', ClinicCampaignTab, {
          children: [
            route('../campaign', ClinicCampaignView, { name: 'clinic.campaign', meta: { title: '안 건강 캠페인' }, alias: [''] }),
            route('past', ClinicCampaignPastList, { name: 'clinic.campaign.past', meta: { title: '종료된 안 건강 캠페인 리스트' } }),
            route('past/:brdPostId', ClinicCampaignPastView, { name: 'clinic.campaign.past.view', meta: { title: '종료된 안 건강 캠페인' } }),
          ],
        }),
        route('manual', ClinicManualTab, {
          children: [
            route('../manual', ClinicManualList, { name: 'clinic.manual', meta: { title: '콘택트렌즈 상식' }, alias: [''] }),
            route('caution', ClinicManualList, { name: 'clinic.manual.caution', meta: { title: '주의사항 및 사용법' } }),
            route('caution/:brdPostId', ClinicManualView, { name: 'clinic.manual.caution.view', meta: { title: '주의사항 및 사용법' } }),
            route('astigmatic-test', ClinicManualAstigmaticTest, { name: 'clinic.manual.astigmatic-test', meta: { title: '셀프 난시 테스트' } }),
            route(':brdPostId', ClinicManualView, { name: 'clinic.manual.view', meta: { title: '콘택트렌즈 상식' } }),
          ],
        }),
        route('vision-center', ClinicVisionCenterTab, {
          children: [
            route('../vision-center', ClinicVisionCenter, { name: 'clinic.vision-center', meta: { title: '정밀시력검사 소개' }, alias: [''] }),
            route('test-process', ClinicVisionCenterTestProcess, { name: 'clinic.vision-center.test-process', meta: { title: '검사 프로세스' } }),
            route('benefit', ClinicVisionCenterBenefit, { name: 'clinic.vision-center.benefit', meta: { title: '혜택' } }),
            route('map', ClinicVisionCenterMap, { name: 'clinic.vision-center.map', meta: { title: '오시는 길' } }),
          ],
        }),
        route('reservation', ClinicReservation, { name: 'clinic.reservation', meta: { title: '정밀시력검사 예약 서비스' } }),
      ],
    }),
    route('franchise-info', FranchiseInfoTab, {
      children: [
        route('../franchise-info', FranchiseInfo, { name: 'franchise-info', meta: { title: '가맹안내 - 개설안내' }, alias: [''] }),
        route('interior', FranchiseInfoInterior, { name: 'franchise-info.interior', meta: { title: '가맹안내 - 인테리어' } }),
        route('cost', FranchiseInfoCost, { name: 'franchise-info.cost', meta: { title: '가맹안내 - 가맹비' } }),
      ],
    }),
    route('sample/request/:productId', SampleRequest, { name: 'sample.request', meta: { title: '샘플 예약하기', requireToken: true } }),
    route('sample/request/detail/:reservationId', SampleRequestDetail, { name: 'sample.request.detail', meta: { title: '샘플예약 상세', requireToken: true } }),
    route('sample/list', SampleRequestList, { name: 'sample.list', meta: { title: '신청 가능 렌즈 목록', requireToken: true } }),
    route('sample', SampleHistoryViewTab, {
      children: [
        route('history-current', SampleHistoryCurrent, { name: 'sample.history-current', meta: { title: '현재 샘플 예약 내역', requireToken: true }, alias: [''] }),
        route('history-past', SampleHistoryPast, { name: 'sample.history-past', meta: { title: '지난 샘플 예약 내역', requireToken: true } }),
      ],
    }),
    route('purchase', PurchaseList, { name: 'purchase', meta: { title: '구매내역', requireToken: true } }),
    route('purchase/:saleNo', PurchaseDetail, { name: 'purchase.detail', meta: { title: '구매내역 상세', requireToken: true } }),
    route('visit/request/detail/:reservationId', VisitRequestDetail, { name: 'visit.request.detail', meta: { title: '샘플예약 상세', requireToken: true } }),
    route('visit', VisitHistoryViewTab, {
      children: [
        route('history-current', VisitHistoryCurrent, { name: 'visit.history-current', meta: { title: '현재 방문 신청 내역', requireToken: true}, alias: [''] }),
        route('history-past', VisitHistoryPast, { name: 'visit.history-past', meta: { title: '지난 방문 신청 내역', requireToken: true } }),
      ],
    }),
    route('coupon', CouponViewTab, {
      children: [
        route('owned-coupon', OwnedCoupon, { name: 'coupon.owned-coupon', meta: { title: '보유 쿠폰',requireToken: true }, alias: [''] }),
        route('used-coupon', UsedCoupon, { name: 'coupon.used-coupon', meta: { title: '사용한 쿠폰', requireToken: true } }),
      ],
    }),
    route('cart', CartView, { name: 'cart', meta: { title: '방문 예약 신청', noFooter: true, requireToken: true } }),
    route('popup-store', PopUpStore, { name: 'popup-store', meta: { title: '팝업스토어', noFooter: true, requireToken: true } }),
    route('check-verify-coupon', CheckVerifyCoupon, { name: 'check-verify-coupon', meta: { title: '본인확인', noFooter: false, requireToken: true } }),
  ]),
  layout('/test', BlankLayout, [
    route('my-device', MyDevice, { name: 'test.my-device', meta: { title: '접속 기기 정보' } }),
  ]),
  {
    path: '/promotion/result', redirect: to => { // 기존 url redirect 처리
      const { query } = to;

      if (query?.promotionSeq) {
        return { name: 'product.bundle', params: { bundleId: query.promotionSeq }, query: null };
      } else {
        return { name: 'main', params: null, query: null };
      }
    },
  },
  route('*', NotFound, { meta: { title: '페이지가 없습니다' } }),
];

Vue.use(VueRouter);
Vue.use(VueMeta);

// 중복 routing 방지
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
};

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let duration = 0;
    let scrollTo = isIOS && 1 || 0;
    let scrollToForBug = isIOS && 1 || 0;
    let additionalScrollY = 100;

    if (to.name === 'main') {
      additionalScrollY = 160;
    }

    if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    duration = Math.log(scrollTo + 1) * 40;
    scrollTo = scrollTo + additionalScrollY;

    Vue.nextTick(() => {
      setTimeout(() => {
        goTo(scrollTo, { duration: duration / 4 });
      }, duration);
    });

    return { x: 0, y: scrollToForBug };
  },
});

router.beforeEach((to, from, next) => {
  document.title = '오렌즈 - ' + to.meta.title;

  // subdomain 이 다른 경우
  if (`${window.location.protocol}//${window.location.host}` !== process.env.VUE_APP_BASE_URL) {
    // console.log('달라요', `${window.location.protocol}//${window.location.host}`, process.env.VUE_APP_BASE_URL, `${process.env.VUE_APP_BASE_URL}${to.fullPath}`);
    return window.location.href = `${process.env.VUE_APP_BASE_URL}${to.fullPath}`;
  }

  // 로그인 회원이 인증정보가 없을 경우
  if (Vue.$cookies.get('accessToken') && Vue.$cookies.get('userType') === '01'
      && !to.matched.some(r => ['member.authentication', 'nice.start', 'nice.blank', 'nice.success', 'nice.fail'].includes(r.name))) {
    assignVuexInfo();

    return router.push({
      name: 'member.authentication',
      query: { redirect: to.fullPath },
    });
  }

  if (!Vue.$cookies.get('accessToken') && !to.matched.some(r => ['login'].includes(r.name)) && to.meta.requireToken) {
    if (Vue.$cookies.get('refreshToken')) {
      axios.post('/v2/auth/token-refresh', {   ///auth/reissue-token
        refreshToken: Vue.$cookies.get('refreshToken'),
      }).then(response => {
        Vue.$cookies.set('accessToken', response.data.data.tokenInfo.accessToken, '7d');
        Vue.$cookies.set('refreshToken', response.data.data.tokenInfo.refreshToken, '14d');

        assignVuexInfo();

        return next();
      }).catch(() => { // 응답 에러 or data가 정상이 아닐 경우
        Vue.$cookies.remove('accessToken');
        Vue.$cookies.remove('refreshToken');

        return router.push({
          name: 'login',
          query: { redirect: to.fullPath },
        });
      });
    } else {
      return router.push({
        name: 'login',
        query: { redirect: to.fullPath },
      });
    }
  } else {
    assignVuexInfo();
    
    // 이미 로그인 되었을 경우 현재페이지 이동
    if (to.name === 'login' && Vue.$cookies.get('accessToken')) {
      const redirect = to.query.redirect;
      if (redirect) {
        // 'redirect' 쿼리 파라미터가 있다면 해당 페이지로 이동
        next({ path: decodeURIComponent(redirect) });
      } else {
        // 'redirect' 쿼리 파라미터가 없다면 기본 페이지로 이동
        next({ name: 'main' });
      }
    }

    // PC의 경우 zoom
    if (!isMobile) {
      document.body.classList.add('pc-zoom');

      // PC 메인일 경우 별도 메인으로 이동
      if (to.path === '/') {
        // console.log(to);
        // return window.location.href = '/pc/index.html';
      }
    }

    return next();
  }
});
router.afterEach(() => {
  //html에 application--dialog-opened가 클래스 네임으로 있는경우 화면 scroll이 안됨
  //ex) dialog를 띄운채로 화면이동시

  if (document.getElementsByClassName('application--dialog-opened')?.length > 0) {
    document.getElementsByClassName('application--dialog-opened')[0].className="";
  }
});

// router.beforeResolve((to, from, next) => {
//   return next();
// });

function layout (path, component, children = [], meta = {}) {
  if (typeof path === 'string') path = { path };
  return {
    ...path,
    component,
    props: true,
    meta: meta,
    children,
  };
}

function route (path, component, route = {}, beforeEnter) {
  return {
    path,
    component,
    props: true,
    beforeEnter,
    ...route,
  };
}

function assignVuexInfo () {
  assignCommonCode();
  assignAuth();
}

function assignAuth () {
  if (Vue.$cookies.get('accessToken') && !store.get('app/accessToken')) {
    store.set('app/accessToken', Vue.$cookies.get('accessToken'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${store.get('app/accessToken')}`;
  }

  if (Vue.$cookies.get('refreshToken') && !store.get('app/refreshToken')) {
    store.set('app/refreshToken', Vue.$cookies.get('refreshToken'));
  }

  if (Vue.$cookies.get('custId') && !store.get('app/custId')) {
    store.set('app/custId', Vue.$cookies.get('custId'));
  }

  if (Vue.$cookies.get('userId') && !store.get('app/userId')) {
    store.set('app/userId', Vue.$cookies.get('userId'));
  }

  if (Vue.$cookies.get('userNm') && !store.get('app/userNm')) {
    store.set('app/userNm', Vue.$cookies.get('userNm'));
  }

  if (Vue.$cookies.get('userType') && !store.get('app/userType')) {
    store.set('app/userType', Vue.$cookies.get('userType'));
  }

  if (Vue.$cookies.get('isSns') && !store.get('app/isSns')) {
    store.set('app/isSns', Vue.$cookies.get('isSns'));
  }

  if (Vue.$cookies.get('signShopNm') && !store.get('app/signShopNm')) {
    store.set('app/signShopNm', Vue.$cookies.get('signShopNm'));
  }
}

function assignCommonCode () {
  if (!store.get('code/common')) {
    axios.get('/v2/common/code/detail', {
      params: {
        filter: 'cdClsTpCd,cdDtlVal,cdDtlDesc,cdDtlSort',
        useYn: 'Y',
      },
    }).then(response => {
      store.set('code/common', response.data.data);
    }).catch(() => { // 응답 에러 or data가 정상이 아닐 경우
      console.log('공통코드 권한 없음');
    });
  }
}

export default router;
