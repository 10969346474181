import Vue from 'vue';
import router from '@/router';
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.timeout = 5000;

axios.interceptors.request.use(
    (config) => {
      if (config.url.startsWith("/v2/common/code/detail")) {
        config.baseURL = process.env.VUE_APP_API_CDN_URL || config.baseURL;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
);

let refreshSubscribers = [];
const onRefreshed = (token) => {
  try {
    refreshSubscribers.map((cb) => cb(token));
  } catch(e) {
    console.error(e);
  } finally {
    refreshSubscribers.length = 0;
  }
};
const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

let isRefreshing = false;

async function redirectToLogin(redirect) {
  axios.defaults.headers.common['Authorization'] = null;
  Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie));
  return await router.push({ name: 'login', query: { redirect }});
}

axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error?.config;
      if (!originalConfig) {
        return router.push({
          name: 'login',
          query: {redirect: router.currentRoute.fullPath},
        });
      }

      const status = error.response?.status;
      if (!originalConfig._retry && !originalConfig.url.includes('auth') && status === 401 && Vue.$cookies.get('refreshToken')) {
        if (isRefreshing) {
          // 토큰 갱신이 진행 중일 때 대기
          return new Promise((resolve) => {
            subscribeTokenRefresh((token) => {
              originalConfig.headers['Authorization'] = 'Bearer ' + token;
              resolve(axios(originalConfig));
            });
          });
        }

        isRefreshing = true;
        originalConfig._retry = true;

        try {
          const reissueTokenResult = await axios.post('/v2/auth/reissue-token', {
            refreshToken: Vue.$cookies.get('refreshToken'),
          });

          const { accessToken, refreshToken } = reissueTokenResult.data.data.tokenInfo;

          Vue.$cookies.set('refreshToken', refreshToken);
          Vue.$cookies.set('accessToken', accessToken);

          axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          originalConfig.headers['Authorization'] = `Bearer ${accessToken}`;

          onRefreshed(accessToken);

          return axios(originalConfig);
        } catch (_error) {
          console.error(_error);
          return await redirectToLogin(router.currentRoute.fullPath);
        } finally {
          isRefreshing = false;
        }
      } else if (status === 401 && router.currentRoute.name !== 'login') {
        isRefreshing = false;
        return await redirectToLogin(router.currentRoute.fullPath);
      }

      return Promise.reject(error);
    },
);

Vue.prototype.$axios = axios;