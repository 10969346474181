// Utilities
import { make } from 'vuex-pathify'

const state = {
  custId: null,
  userId: null,
  userNm: null,
  userType: null,
  accessToken: null,
  refreshToken: null,
  signShopNm:null,
  isSns: false,
}

const getters = {
  ...make.getters(state),
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
