import {
  mdiAccountOutline,
  mdiAlert, mdiAlphaACircle, mdiAlphaQCircle,
  mdiArrowUp, mdiAt, mdiBellOutline,
  mdiCached, mdiCalendar, mdiChatOutline,
  mdiCheck, mdiCheckBold,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiCheckCircle,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight, mdiChevronUp,
  mdiCircle,
  mdiClose,
  mdiCloseCircle, mdiCog,
  mdiExclamation, mdiEyeLockOutline, mdiEyeOutline, mdiFeatureSearchOutline, mdiHeart, mdiHeartOutline, mdiHumanFemale, mdiHumanMale, mdiInformation, mdiLinkVariantPlus, mdiLock, mdiLogout, mdiMagnify,
  mdiMenu,
  mdiMenuDown, mdiMinus,
  mdiMinusBox, mdiNumeric0Circle, mdiNumeric0CircleOutline, mdiNumeric1Circle, mdiNumeric1CircleOutline, mdiNumeric2Circle, mdiNumeric2CircleOutline, mdiNumeric3Circle, mdiNumeric3CircleOutline, mdiNumeric4Circle, mdiNumeric4CircleOutline, mdiNumeric5Circle, mdiNumeric5CircleOutline, mdiNumeric6Circle, mdiNumeric6CircleOutline,
  mdiNumeric7Circle, mdiNumeric7CircleOutline, mdiNumeric8Circle, mdiNumeric8CircleOutline, mdiNumeric9Circle, mdiNumeric9CircleOutline,
  mdiPageFirst,
  mdiPageLast, mdiPaperclip,
  mdiPencil, mdiPencilOutline, mdiPlus,
  mdiRadioboxBlank,
  mdiRadioboxMarked, mdiShareVariantOutline,
  mdiStar,
  mdiStarHalfFull,
  mdiStarOutline,
  mdiUnfoldMoreHorizontal,
  mdiAccountEdit,
  mdiPaletteSwatchOutline,
  mdiEyeCheckOutline,
  mdiCheckCircleOutline,mdiMapMarker,mdiMapMarkerRadiusOutline,mdiCrosshairsGps,mdiEye,mdiEyeRemoveOutline,
  mdiAlertCircle,mdiReload
} from '@mdi/js';
import checkboxOff from '@/assets/images/icon/checkboxOff.vue';
import checkboxOn from '@/assets/images/icon/checkboxOn.vue';

const mdiSvgIcons = {
  iconfont: 'mdiSvg',
  values: {
    // Vuetify default
    complete: mdiCheck,
    cancel: mdiCloseCircle,
    close: mdiClose,
    delete: mdiCloseCircle, // delete (e.g. v-chip close)
    clear: mdiClose,
    success: mdiCheckCircle,
    checkOff: mdiCheckCircleOutline,
    checkOn: mdiCheckCircle,
    info: mdiInformation,
    warning: mdiExclamation,
    error: mdiAlert,
    alert: mdiAlertCircle,
    prev: mdiChevronLeft,
    next: mdiChevronRight,
    checkboxOn: mdiCheckboxMarked,
    checkboxOff: mdiCheckboxBlankOutline,
    checkboxIndeterminate: mdiMinusBox,
    delimiter: mdiCircle, // for carousel
    sort: mdiArrowUp,
    expand: mdiChevronDown,
    menu: mdiMenu,
    subgroup: mdiMenuDown,
    dropdown: mdiMenuDown,
    radioOn: mdiRadioboxMarked,
    radioOff: mdiRadioboxBlank,
    edit: mdiPencil,
    ratingEmpty: mdiStarOutline,
    ratingFull: mdiStar,
    ratingHalf: mdiStarHalfFull,
    loading: mdiCached,
    first: mdiPageFirst,
    last: mdiPageLast,
    unfold: mdiUnfoldMoreHorizontal,
    file: mdiPaperclip,
    plus: mdiPlus,
    minus: mdiMinus,

    // 추가
    search: mdiMagnify,
    notification: mdiBellOutline,
    review: mdiPencilOutline,
    kakaoTalk: mdiChatOutline,
    roundup: mdiFeatureSearchOutline,
    favorite: mdiHeartOutline,
    favoriteOn: mdiHeart,
    myPage: mdiAccountOutline,
    share: mdiShareVariantOutline,
    checkboxOnBold: mdiCheckBold,
    top: mdiChevronUp,
    starOn: mdiStar,
    starOff: mdiStarOutline,
    secureOn: mdiEyeLockOutline,
    secureOff: mdiEyeOutline,
    num0: mdiNumeric0Circle,
    num0Outline: mdiNumeric0CircleOutline,
    num1: mdiNumeric1Circle,
    num1Outline: mdiNumeric1CircleOutline,
    num2: mdiNumeric2Circle,
    num2Outline: mdiNumeric2CircleOutline,
    num3: mdiNumeric3Circle,
    num3Outline: mdiNumeric3CircleOutline,
    num4: mdiNumeric4Circle,
    num4Outline: mdiNumeric4CircleOutline,
    num5: mdiNumeric5Circle,
    num5Outline: mdiNumeric5CircleOutline,
    num6: mdiNumeric6Circle,
    num6Outline: mdiNumeric6CircleOutline,
    num7: mdiNumeric7Circle,
    num7Outline: mdiNumeric7CircleOutline,
    num8: mdiNumeric8Circle,
    num8Outline: mdiNumeric8CircleOutline,
    num9: mdiNumeric9Circle,
    num9Outline: mdiNumeric9CircleOutline,
    male: mdiHumanMale,
    female: mdiHumanFemale,
    at: mdiAt,
    calendar: mdiCalendar,
    lock: mdiLock,
    setting: mdiCog,
    logout: mdiLogout,
    link: mdiLinkVariantPlus,
    alphaQ: mdiAlphaQCircle,
    alphaA: mdiAlphaACircle,
    account: mdiAccountEdit,
    sample: mdiPaletteSwatchOutline,
    checking: mdiEyeCheckOutline,
    location: mdiMapMarker,
    currrentlocation: mdiMapMarkerRadiusOutline,
    locationGps: mdiCrosshairsGps,
    inStock: mdiEye,
    noStock: mdiEyeRemoveOutline,
    reload: mdiReload,
    customCheckboxOff: {
      component: checkboxOff,
    },
    customCheckboxOn: {
      component: checkboxOn,
    },
  },
};

export default mdiSvgIcons;