import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import '@/plugins/vuetify-dialog';
import '@/plugins/axios';
import '@/plugins/vue-cookies';
import '@/plugins/vue-clipboard2';
import store from './store';
import router from './router';
import App from '@/App';

import '@/sass/main.scss';
import '@/assets/functions/image_lazy_loading';

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
